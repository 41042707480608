import {Outlet, Navigate, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/auth";

const PrivateRoutes = () => {
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    console.log('auth:', auth);
    console.log('token:', auth.token);

    const isValidToken = () => {

        if (auth.token && auth.token.length) {
            const jwtPayload = JSON.parse(window.atob(auth.token.split('.')[1]));
            const expiration = new Date(jwtPayload.exp * 1000);
            const now = new Date();

            if (expiration.getTime() <= now.getTime()) {
                console.log("---- Token expired!!", expiration, now);
                dispatch(logout());
                return false;
            }
            console.log('--- token is valid', expiration, now);
        } else {
            return false;
        }

        return true;
    }

    const location = useLocation();
    console.log(location.pathname);

    return(
        isValidToken() ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes

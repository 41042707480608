import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showError: false,
    error: {title: null, message: null, error: null},
    loading: false
}

export const utilSlice = createSlice({
    name: 'util',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = {title: action.payload.title, message: action.payload.message, error: action.payload.error};
        },
        setShowError: (state, action) => {
            state.showError = action.payload;
            if (!action.payload) {
                state.error = initialState.error;
            }
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setError, setShowError, setLoading } = utilSlice.actions;

export default utilSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

const initialState = {
    user: null,
    followups: null,
    token: null,
    calendar: null,
    needRefresh: false,
    appointment: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: () => {},
        setUser: (state, action) => {
            console.log(action.payload);
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.followups = action.payload.followups;
            state.appointment = action.payload.appointment;
        },
        logout: (state) => {
            // state.token = null;
            // state.user = null;
            console.log('clearing state...');
            storage.removeItem('persist:root');
            window.location.reload();
        },
        setCalendar: (state, action) => {
            console.log(action.payload);
            state.calendar = action.payload;
        },
        updateFollowup: (state, action) => {
            const index = state.followups.findIndex(item => item.id === action.payload.id);
            state.followups[index] = action.payload;
        },
        removeDrop: (state, action) => {
            state.user.eye_drops.splice(action.payload, 1);
        },
        addDrop: (state, action) => {
            state.user.eye_drops.push(action.payload);
        },
        setNeedRefresh: (state, action) => {
            state.needRefresh = action.payload;
        },
        updateEmail: (state, action) => {
            state.user.email = action.payload;
        },
        updateMobile: (state, action) => {
            state.user.mobile_phone = action.payload;
        },
    },
});

export const { login, setUser, logout, setCalendar, updateFollowup, removeDrop, addDrop, setNeedRefresh, updateEmail, updateMobile } = authSlice.actions;

export default authSlice.reducer;

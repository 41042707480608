import {IconButton, List, Typography, ListItemText, ListItem} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/system";
// import "@fontsource/montez";
import theme, {Colors, DrawerWidth} from "../theme";

export const AppbarContainer = styled(Box, theme)(() => ({
    display: 'flex',
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 8px',
    width: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: '1px solid',
    borderBottomColor: Colors.primary,

    [theme.breakpoints.down('md')]: {
        width: '100%',
    },

}));

export const AppbarHeader = styled(Typography)(() => ({
    padding: "4px",
    flexGrow: 1,
    fontSize: "4em",
    fontFamily: '"Roboto", "cursive"',
    color: Colors.secondary,

    [theme.breakpoints.down('md')]: {
        marginLeft: -70
    },
}));

export const ActionIconsContainerMobile = styled(Box)(() => ({
    display: 'flex',
    background: Colors.shaft,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
    zIndex: 99,
    borderTop: `1px solid ${Colors.border}`
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
    flexGrow: 0,
}));

export const MyList = styled(List)(({type}) => ({
    display: type === "row" ? "flex" : "block",
    flexGrow: 3,
    justifyContent: "center",
    alignItems: "center",
}));

export const MyListItemText = styled(ListItemText)({
    '& .MuiListItemText-primary': {
        fontSize: 12,
    }
});

export const MyListItem = styled(ListItem)({
    width: 'auto',
    margin: 5,
});

export const DrawerCloseButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: 10,
    left: DrawerWidth,
    zIndex: 1999,
}));

import {DashboardButton, Description, Title} from "../../styles/screens";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "../../redux/auth";
import {useEffect} from "react";
import moment from "moment-timezone";

export default function LandingScreen() {
    const navigate = useNavigate();

    const APP_ERROR = useSelector(state => state.util.error);
    const user = useSelector(state => state.auth.user);

    const t = moment().format('MMMM Do YYYY, h:mm:ss a');

    useEffect(() => {
        setTimeout(() => {
            console.log('user:', user);
            if (user) {
                navigate('/home');
            }
        } , 1000);

    }, [user]);

    return (
        <Box sx={{width: '100%'}}>
            <Title variant={'h1'}>Welcome to OCC Eye Care</Title>
            <Description variant={'body1'}>
                <br/>
                If you are already a patient, Login below, otherwise contact us for an inquiry.
            </Description>

            <Grid container spacing={3} sx={{}}>
                <Grid item md={4} xs={12}>
                    <DashboardButton type="submit" variant="contained" onClick={() => {navigate('/login');}} disabled={true}>Patients Dashboard</DashboardButton>
                </Grid>
                <Grid item md={4} xs={12}>
                    <DashboardButton type="submit" variant="contained" onClick={() => {navigate('/login', { state: { mode: 'drops' } });}}>Manage Drops</DashboardButton>
                </Grid>
                <Grid item md={4} xs={12}>
                    <DashboardButton sx={{backgroundColor: '#333'}} type="submit" variant="contained" onClick={() => {window.location.replace('https://operas.occeyecare.ca/patient-form')}}>Contact Us</DashboardButton>
                </Grid>
            </Grid>

        </Box>
    );
}

const FollowUp = class {
    id;
    pvdrno;
    doctor;
    chart_no;
    dob;
    appt_in;
    appt_unit;
    tests = [];
    status;
    ref_no;
    bookno;

    drops = "";

    costs = {};

    days_for_next_appt = 0;
    nextApptDate = null;
    nextApptTime = "00:00";

    appt_interval = "0-D";

    allowedDateRange = 2;

    hasTests = false;

    isSameDay = false;
    isTestOnlyBooking = false;
    docApptDate = null;

    haveDrops = false;
    isNewConsent = false;

    fromArchive = false;

    // eslint-disable-next-line no-useless-constructor
    constructor() {

    }

    calcDays() {
        if (!this.id) return;
        if (this.appt_unit.toLowerCase().includes("week")) {
            this.days_for_next_appt = this.appt_in * 7;
            this.appt_interval = this.appt_in * 7 + "-D";
            this.allowedDateRange = 2;
        } else if (this.appt_unit.toLowerCase().includes("month")) {
            this.days_for_next_appt = this.appt_in * 30;
            this.appt_interval = this.appt_in + "-M";

            if (this.appt_in >= 4 && this.appt_in < 6) this.allowedDateRange = 5;
            if (this.appt_in >= 6 && this.appt_in < 9) this.allowedDateRange = 7;
            if (this.appt_in >= 9) this.allowedDateRange = 12;
        } else if (this.appt_unit.toLowerCase().includes("year")) {
            this.days_for_next_appt = this.appt_in * 365;
            this.appt_interval = this.appt_in + "-Y";
            this.allowedDateRange = 12;
        } else {
            this.days_for_next_appt = this.appt_in;
            this.appt_interval = this.appt_in + "-D";
            this.allowedDateRange = 2;
        }
    }
};

export default FollowUp;

import axios from 'axios';
import {Buffer} from 'buffer';
import storage from 'redux-persist/lib/storage';
import {setError, setLoading, setShowError} from "../redux/util";

const token = `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`;
const encodedToken = Buffer.from(token).toString('base64');
let store;

export const injectStore = _store => {
    store = _store;
}

console.log(process.env.REACT_APP_API_URL);
console.log(process.env.REACT_APP_API_USERNAME, process.env.REACT_APP_API_PASSWORD);

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.defaults.headers.common['Authorization'] = 'Basic '+ encodedToken;

instance.interceptors.request.use((request) => {
    request.headers.access_token = store.getState().auth.token;
    request.headers["Authorization"] = "Bearer " + store.getState().auth.token;
    console.log("setting token to:", store.getState().auth.token);

    // console.log("interceptors", request);
    store.dispatch(setLoading(true));
    return request;
});

instance.interceptors.response.use((response) => {
        store.dispatch(setLoading(false));
        console.log("interceptors", response);
        return response;
    },
    error => {
        store.dispatch(setLoading(false));
        console.warn("interceptors", error);
        if (error.response?.status && error.response?.status === 401) {
            // window.location.href = '/';
            console.warn("Authorization failed!");

            storage.removeItem('persist:root');
            window.location.href = '/';

            return {data: null, error: error.response};
        } else if (error.response?.status && error.response?.status === 404) {
            // console.log(error);
            return error.response;
        } else {
            store.dispatch(setShowError(true));
            store.dispatch(setError({
                title: "Network Call Failed!",
                message: error.message,
                error: error.toString()
            }));
            // console.log('show error ture');
            return {data: null, error: error};
        }
    });

export default instance;

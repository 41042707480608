import {
    AppointmentNumber,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle, RemoveButton,
    Title
} from "../../styles/screens";
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar, Link, ListItem,
    ListSubheader, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import Theme, {Colors} from "../../styles/theme";
import InfoIcon from '@mui/icons-material/AddCircle';
import {useEffect, useRef, useState} from "react";
import {addDrop, setCalendar as clearCalendar} from "../../redux/auth";
import {fetchDrops, setDropsStatus} from "../../redux/api";
import {useDispatch, useSelector} from "react-redux";

export default function AddDropsScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const breakpoints = Theme.breakpoints.values;

    const [open, setOpen] = useState(false);
    const [dropsList, setDropsList] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [drop, setDrop] = useState('');

    const [noOfDrops, setNoOfDrops] = useState(1);
    const [eye, setEye] = useState('Left');
    const [times, setTimes] = useState(1);

    const dropsStatus = useSelector(state => state.api.dropsStatus);
    const drops = useSelector(state => state.api.drops);

    useEffect(() => {
        dispatch(fetchDrops());
    }, []);

    useEffect(() => {
        setDropsList(drops);
    }, [drops]);

    const handleClickOpen = (dropname) => {
        setOpen(true);
        console.log(drop, dropname);
        setDrop(dropname);
    };

    const handleClose = () => {
        setOpen(false);
        //setDrop('');
    };

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 2
        } else if (width < breakpoints.md) {
            return 4
        } else {
            return 6
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth));

    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const search = () => {
         setDropsList(drops.filter(o => o.name.toLowerCase().includes(searchText.toLowerCase())));
    }

    const add = () => {
        dispatch(addDrop(drop + " - " + noOfDrops + " drop(s) " + times + " times per day on " + eye + " Eye(s)"));
        setNoOfDrops(1);
        setTimes(1);
        setEye('Left');
        setOpen(false);
        setDrop('');
        dispatch(setDropsStatus(false));
        //navigate('/manage_drops');
    }

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Add Drops</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>Please list all the eye drops you are currently using</Description>



            <Grid container spacing={3}>
                <Grid item md={3} xs={8}>
                    <TextField value={searchText} onChange={(event) => {setSearchText(event.target.value)}} placeholder="Search eye drops" sx={{width: '100%'}}></TextField>
                </Grid>
                <Grid item md={2} xs={4}>
                    <EditButton onClick={() => {search()}}>Search</EditButton>
                </Grid>
                <Grid item md={3} xs={8}>
                    <TextField value={drop} onChange={(event) => {setDrop(event.target.value)}} placeholder="Eye drop name" sx={{width: '100%'}}></TextField>
                </Grid>
                <Grid item md={2} xs={4}>
                    <EditButton onClick={() => {handleClickOpen(drop)}}>Add New</EditButton>
                </Grid>
                <Grid item md={2} xs={12}>
                    <EditButton onClick={() => {navigate('/manage_drops');}}>DONE</EditButton>
                </Grid>
            </Grid>

            <ImageList sx={{ width: "100%" }} cols={columns}>
                <ImageListItem key="Subheader" cols={columns}>
                    <ListSubheader component="div">Eye Drops</ListSubheader>
                </ImageListItem>
                {dropsList.map((item) => (

                        <ImageListItem key={item.id}>
                            <img
                                src={`${item.image_full_path.includes('http:') ? item.image_full_path.replace('http:', 'https:') : item.image_full_path}?w=248&fit=crop&auto=format`}
                                srcSet={`${item.image_full_path.includes('http:') ? item.image_full_path.replace('http:', 'https:') : item.image_full_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.name}
                                loading="lazy"
                                onClick={() => {handleClickOpen(item.name)}}
                            />
                            <ImageListItemBar
                                onClick={() => {handleClickOpen(item.name)}}
                                title={item.name}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 1)' }}
                                        aria-label={`info about ${item.name}`}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                ))}
            </ImageList>

            <Grid container spacing={3} sx={{marginTop: 0.5}}>

                <Grid item md={12} xs={12}>
                    <hr/>
                </Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={6}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={4}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {navigate('/manage_drops');}}>Done</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth={true}>
                <DialogTitle>Dosage</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        How often do you use {drop}?
                    </DialogContentText>
                    <Grid container spacing={3} sx={{marginTop: 0.5}}>

                        <Grid item md={2} xs={6}>
                            <Select
                                sx={{width:'100%'}}
                                value={noOfDrops ?? 0}
                                onChange={(event) => {setNoOfDrops(event.target.value)}}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography>Drop(s)</Typography>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Select
                                sx={{width:'100%'}}
                                value={times ?? 0}
                                onChange={(event) => {setTimes(event.target.value)}}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography>Times per day on</Typography>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Select sx={{width:'100%'}}
                                    value={eye ?? 0}
                                    onChange={(event) => {setEye(event.target.value)}}
                            >
                                <MenuItem value={'Left'}>Left</MenuItem>
                                <MenuItem value={'Right'}>Right</MenuItem>
                                <MenuItem value={'Both'}>Both</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography>Eye(s)</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <FormButtonSecondary type="submit" variant="contained" onClick={handleClose}>Cancel</FormButtonSecondary>
                    <PrimaryButton type="submit" variant="contained" onClick={() => {add()}}>Add</PrimaryButton>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SubscribeTf, FooterTitle } from "../../styles/footer";
import SendIcon from "@mui/icons-material/Send";

export default function Footer() {
  return (
    <Box
      sx={{
        background: Colors.primary,
        color: Colors.white,
        pl: 5,
        pr: 5,
        pt: 1,
        pb: 1,
        fontSize: { xs: '10px', md: '10px' }
      }}
    >
      <Grid container spacing={0} justifyContent="center">
        <Grid item md={8} xs={8} sx={{textAlign: 'left'}}>
          <FooterTitle variant="div">
              <h5 className="copyright2"><span className="copyright3">© </span>Copyright 2017 OCC Eyecare. All Rights
                  Reserved.</h5>
          </FooterTitle>
        </Grid>
        <Grid item md={4} xs={4} sx={{textAlign: 'right'}}>
          <FooterTitle variant="div">
              <h5><a href="http://occeyecare.ca/footer_legal" style={{color: 'white'}}>Legal / Medical Disclaimer</a></h5>
          </FooterTitle>
        </Grid>
      </Grid>
    </Box>
  );
}

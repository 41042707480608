import {
    AppbarContainer,
    AppbarHeader,
    MyList, MyListItem,
    MyListItemText,
} from "../../styles/appbar";

export default function AppbarDesktop() {

    return (
        <AppbarContainer>
            <AppbarHeader variant="h4">
                <a href="/home">
                <img src="/occ_logo.png" alt="Logo"/>
                </a>
            </AppbarHeader>
            <MyList type="row">
                <MyListItem button component="a" href="http://occeyecare.ca/">
                <MyListItemText primary="OCC HOME"/>
                </MyListItem>
                <MyListItem button component="a" href="http://occeyecare.ca/COVID19/">
                <MyListItemText primary="COVID19 UPDATE"/>
                </MyListItem>
                <MyListItem button component="a" href="http://occeyecare.ca/about_us/">
                <MyListItemText primary="ABOUT"/>
                </MyListItem>
                <MyListItem button component="a" href="http://occeyecare.ca/new_patients/">
                <MyListItemText primary="PATIENTS"/>
                </MyListItem>
                <MyListItem button component="a" href="http://occeyecare.ca/Treatments_and_Tests/">
                <MyListItemText primary="TREATMENTS AND TESTS"/>
                </MyListItem>
                <MyListItem button component="a" href="http://occeyecare.ca/contact_us/">
                <MyListItemText primary="CONTACT US"/>
                </MyListItem>
            </MyList>
        </AppbarContainer>
    );
}

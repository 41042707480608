import {put, call} from "redux-saga/effects";
import {loginRequest} from "../requests/auth";
import {setUser} from "../../auth";
import {setError, setShowError} from "../../util";
import Patient from "../../../classes/patient";

export function* loginHandler(action) {
    try {
        const response = yield call(loginRequest, action.payload);
        const {data} = response.data;

        if (data) {
            const user = new Patient(data);
            const {...userObj} = user;
            delete userObj['followUps'];

            yield put(setUser({
                token: data.token,
                user: userObj,
                followups: user.getFollowUpsArray(),
                appointment: data.appointment
            }));
        } else {
            console.warn("API ERROR:", response);

            if (response.data && response.data.error_code) {
                yield put(setError({
                    title: "Record Not Found!",
                    message: "No patient record found with the provided reference number and details.",
                    error: response.data.error_code
                }));

            } else {
                yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));
            }

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

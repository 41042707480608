import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from "./auth";
import utilReducer from "./util";
import apiReducer from "./api";


import {rootSaga} from "./saga/rootSaga";
import {
   persistStore,
   persistReducer,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
   key: 'root',
   version: 1,
   storage,
   // blacklist: [reducerPath],
}

const rootReducer = combineReducers({
   auth: authReducer,
   util: utilReducer,
   api: apiReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
   reducer: persistedReducer,
   middleware:(getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
   }).concat(sagaMiddleware),
   devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga);

export default store;

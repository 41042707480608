import {
    AppointmentNumber,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Colors} from "../../styles/theme";
import {useSelector} from "react-redux";
import moment from "moment-timezone";


export default function BookingListScreen() {
    const navigate = useNavigate();

    const followups = useSelector(state => state.auth.followups);
    const user = useSelector(state => state.auth.user);

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Appointment Summary</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                You've successfully booked your Appointment(s).
            </Description>

            <Grid container spacing={3} sx={{width: '100%'}}>
                {/*<Grid item md={1.5} xs={0}>*/}
                {/*</Grid>*/}
                <Grid item md={12} xs={12}>
                    <QuestionTitle>For any changes to this appointment, please email - contactus@occeyecare.ca <br/>or Log back into the system.</QuestionTitle>
                </Grid>
                {/*<Grid item md={1.5} xs={0}>*/}
                {/*</Grid>*/}

            </Grid>

                {followups.map((followup, i) => {
                    const date = moment(followup.nextApptDate + " " + followup.nextApptTime, "YYYY-MM-DD HH:mm");
                    return (
                        <Grid container spacing={3} sx={{width: '100%'}} key={i}>
                            <Grid item md={1} xs={12} key={i}>
                                <AppointmentNumber>{i+1}</AppointmentNumber>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Typography>Date of Appointment:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{date.format('dddd, MMMM DD, YYYY')}</Typography>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <Typography>Time of Appointment:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{date.format("h:mm A")}</Typography>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <Typography>Doctor:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{followup.doctor}</Typography>
                            </Grid>

                            <Grid item md={2} xs={12}>
                                <Typography>Reference No:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{followup.ref_no}</Typography>
                            </Grid>


                            <Grid item md={12} xs={12}>
                                <hr/>
                            </Grid>
                        </Grid>
                    )
                })}

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={8} xs={5}>
                    <Typography sx={{textAlign: 'center', color: '#FF5733'}}><br/>Confirmation email sent to {user.email}</Typography>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
            </Grid>

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Go Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {navigate('/home');}}>Continue</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

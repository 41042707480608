import {
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {logout, updateEmail, updateMobile} from "../../redux/auth";
import {useRef, useState, useEffect} from "react";
import {contactUpdate, setContactUpdateStatus} from "../../redux/api";

const myHelper = {
    mobile_phone: {
        required: "Mobile Number is Required",
        pattern: "Invalid mobile number"
    },
    email: {
        required: "Email is Required",
        pattern: "Invalid email address"
    },
};
export default function ContactDetailsScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const contactUpdateStatus = useSelector(state => state.api.contactUpdateStatus);

    const refMobile = useRef(null);
    const refEmail = useRef(null);

    const [controlStates, setControlStates] = useState({
        mobile_phone: true,
        email: true
    });

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const handleOnSubmit = (evt) => {
        console.log(evt);
        dispatch(updateEmail(evt.email));
        dispatch(updateMobile(evt.mobile_phone));
        dispatch(contactUpdate(evt));
    };

    useEffect(() => {
        if (contactUpdateStatus) {
            navigate('/appointment_list');
            dispatch(setContactUpdateStatus(false));
        }
    }, [contactUpdateStatus]);

    const enableInput = (ref) => {
        setControlStates((val) => {
            val[ref.current.name] = false;
            return val;
        });
        ref.current.disabled = false;
        ref.current.focus();
        ref.current.select();
    };

    return (
        <Box sx={{width: '100%'}} component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Book New Appointment</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                STEP 2: Verify your contact details
            </Description>

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={1.5} xs={0}>
                </Grid>
                <Grid item md={9} xs={12}>
                    <QuestionTitle>Please confirm your Mobile number
                        and Email address</QuestionTitle>
                </Grid>
                <Grid item md={1.5} xs={0}>
                </Grid>

                <Grid item md={1.5} xs={0}></Grid>
                <Grid item md={2.5} xs={12}>
                    <MyFormLabel>Mobile Number</MyFormLabel>
                </Grid>
                <Grid item md={5} xs={10}>
                    <Controller
                        control={control}
                        name="mobile_phone"
                        defaultValue={user.mobile_phone}
                        rules={{
                            required: true,
                            pattern: /^[a-z]*\d*$/i
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                className="textInput"
                                type="text"
                                fullWidth
                                disabled={controlStates.mobile_phone}
                                label="Mobile No"
                                error={error !== undefined}
                                helperText={error ? myHelper.mobile_phone[error.type] : ""}
                                inputRef={refMobile}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={2} xs={2}>
                    <EditButton type="button" variant="contained" onClick={() => {enableInput(refMobile)}}>Update</EditButton>
                </Grid>
                <Grid item md={1} xs={0}></Grid>

                <Grid item md={1.5} xs={0}></Grid>
                <Grid item md={2.5} xs={12}>
                    <MyFormLabel>Email</MyFormLabel>
                </Grid>
                <Grid item md={5} xs={10}>
                    <Controller
                        control={control}
                        name="email"
                        defaultValue={user.email}
                        rules={{
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                type="text"
                                className="textInput"
                                fullWidth
                                disabled={controlStates.email}
                                label="Email Address"
                                error={error !== undefined}
                                helperText={error ? myHelper.email[error.type] : ""}
                                inputRef={refEmail}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={2} xs={2}>
                    <EditButton type="button" variant="contained" onClick={() => {enableInput(refEmail)}}>Update</EditButton>
                </Grid>
                <Grid item md={1} xs={0}></Grid>

                <Grid item md={12} xs={12}>
                    <hr/>
                </Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Go Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false}>Continue</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import moment from 'moment-timezone';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import {persistStore} from "redux-persist";
import {injectStore} from "./utils/Api";

injectStore(store);


const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

moment.tz.setDefault("America/Toronto");

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

import {
    AppointmentNumber,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Colors} from "../../styles/theme";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment-timezone";
import {useEffect} from "react";
import {setError, setShowError} from "../../redux/util";
import {cancelBooking, fetchUpcomingAppointments} from "../../redux/api";
import {setNeedRefresh} from "../../redux/auth";


export default function RescheduleScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const appointments = useSelector(state => state.api.upcomingAppointments);
    const isLoading = useSelector(state => state.util.loading);
    const bookStatus = useSelector(state => state.api.bookStatus);

    useEffect(() => {
        dispatch(fetchUpcomingAppointments());
    }, []);

    useEffect(() => {
        dispatch(fetchUpcomingAppointments());
    }, [bookStatus]);


    useEffect(() => {
        if (!isLoading && (!appointments || appointments.length === 0)) {
            dispatch(setError({
                title: "No Appointments to Cancel!",
                message: "You have no active appointment to cancel. Contact hospital for more information.",
                error: null
            }));
            dispatch(setShowError(true));
            //navigate('/home');
        } else {

            dispatch(setShowError(false));

        }
    }, [appointments, isLoading]);

    const cancel = (appointment) => {
        console.log(appointment);
        dispatch(cancelBooking({apptId: appointment.ApptID, fromArchive: appointment.from_archive, followupId: appointment.followup_id ? appointment.followup_id : 0}));
        dispatch(setNeedRefresh(true));
    }

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Upcoming Appointment</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                You've the following appointment booked.
            </Description>

            {/*<Grid container spacing={3} sx={{width: '100%'}}>*/}
            {/*    <Grid item md={1.5} xs={0}>*/}
            {/*    </Grid>*/}
            {/*    <Grid item md={9} xs={12}>*/}
            {/*        <QuestionTitle>If you wish to cancel this appointment, click Cancel Booking.</QuestionTitle>*/}
            {/*    </Grid>*/}
            {/*    <Grid item md={1.5} xs={0}>*/}
            {/*    </Grid>*/}

            {/*</Grid>*/}

                {appointments.map((appointment, i) => {

                    const date = moment(appointment.Date, "YYYY-MM-DD HH:mm:ss");


                    return (
                        <Grid container spacing={3} sx={{width: '100%'}} key={i}>
                            <Grid item md={1} xs={12} key={i}>
                                <AppointmentNumber>{i+1}</AppointmentNumber>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Typography>Date of Appointment:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{date.format('MMMM DD, YYYY')}</Typography>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <Typography>Time of Appointment:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{date.format("h:mm A")}</Typography>
                            </Grid>

                            <Grid item md={2} xs={12}>
                                <Typography>Doctor:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{appointment.Doctor}</Typography>
                            </Grid>

                            <Grid item md={2} xs={12}>
                                <Typography>Reference No:</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{appointment.Reference}</Typography>
                            </Grid>
                            <Grid item md={1} xs={12}>
                                {!appointment.followup_id && <Button variant="contained" onClick={() => {cancel(appointment)}} sx={{backgroundColor: '#FF5733', width: '120px'}}>Cancel</Button>}
                                {appointment.followup_id && <Button variant="contained" onClick={() => {cancel(appointment)}} sx={{backgroundColor: '#FF5733', width: '120px'}}>Cancel</Button>}

                            </Grid>


                            <Grid item md={12} xs={12}>
                                <hr/>
                            </Grid>
                        </Grid>
                    )
                })}

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Go Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {navigate('/home')}}>Done</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

import styled from "@emotion/styled";
import {
  Button,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import theme, { Colors } from "../theme";

export const Title = styled(Typography)(() => ({
  marginBottom: 20,
  fontSize: '2.0rem',
  fontWeight: 'bold',
  textAlign: 'center',
  margin: 'auto',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
    marginBottom: 0,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: 5,
  },
}));

export const Description = styled(Typography)(() => ({
  marginBottom: 60,
  fontSize: '1.0rem',
  textAlign: 'center',
  verticalAlign: 'top',
  marginTop: 10,

  [theme.breakpoints.down('md')]: {
    marginTop: -10
  },

  [theme.breakpoints.down('sm')]: {
    // marginTop: 10
  },
}));

export const MyFormLabel = styled(FormLabel)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',

}));

export const PrimaryButton = styled(Button)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  width: '40%',
  display: 'inline',
  marginLeft: 20,
  marginTop: 40,
  float: 'right'
}));

export const SubscribeTf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: Colors.secondary,
  },

  ".MuiInput-root::before": {
    borderBottom: `1px solid ${Colors.secondary}`,
  },
}));

export const DashboardButton = styled(Button)(() => ({
  fontSize: '0.9rem',
  fontWeight: 'bold',
  width: '100%',
  display: 'inline',
  margin: 'auto',
  height: 80,
}));

export const HomeButton = styled(Button)(() => ({
  fontSize: '0.6rem',
  fontWeight: 'bold',
  width: '100',
  marginTop: -20,

      [theme.breakpoints.down('md')]: {
        display: 'inline',
        float: 'left'
      },
}));

export const QuestionTitle = styled(Typography)(() => ({
  marginBottom: 20,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  color: "#1c75bc",
  [theme.breakpoints.down('md')]: {
    marginTop: -50,
    fontSize: '1.0rem',
  },
}));

export const FormButtonPrimary = styled(Button)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  width: '100%',
  display: 'inline',
  // marginLeft: 20,
  marginTop: 40,
  float: 'right',
  height: 50,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));

export const FormButtonSecondary = styled(Button)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  width: '100%',
  display: 'inline',
  // marginLeft: 20,
  marginTop: 40,
  float: 'right',
  backgroundColor: Colors.white,
  borderWidth: 5,
  borderStyle: 'solid',
  borderColor: Colors.dark,
  color: Colors.primary,
  height: 50,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));


export const EditButton = styled(Button)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  width: 120,
  display: 'inline',
  height: 55,
  backgroundColor: Colors.secondary,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    width: '100%',
    padding: 0
  },
}));

export const BookingDate = styled(Typography)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  // margin: 'auto',
  // width: '100%',
  // paddingLeft: 24,
  paddingTop: 2,
  paddingBottom: 2,
  backgroundColor: Colors.muted,
  height: 23,

  [theme.breakpoints.down('md')]: {
    fontSize: '0.8rem',
    marginBottom: 0,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    marginBottom: 0,
    // paddingLeft: 24,
  },
}));

export const TimeSlotButton = styled(Button)(() => ({
  fontSize: '1.0rem',
  fontWeight: 'bold',
  width: '100%',
  display: 'inline',
  // marginLeft: 20,
  // marginTop: 10,
  float: 'right',
  height: 50,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));

export const AppointmentNumber = styled(Typography)(() => ({
  backgroundColor: Colors.dark,
  width: 50,
  height: 35,
  color: Colors.white,
  textAlign: 'center',
  verticalAlign: 'middle',
  paddingTop: 14,
  paddingBottom: 0,
  borderRadius: 25,
  fontWeight: 'bold'
}));

export const RemoveButton = styled(Button)(() => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  width: 120,
  display: 'inline',
  // height: 55,
  backgroundColor: Colors.danger,
  color: Colors.white,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    width: '100%',
    padding: 0
  },
}));

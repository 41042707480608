import {
    BookingDate,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle, TimeSlotButton,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Colors} from "../../styles/theme";
import {createBooking, loadCalendar, setBookStatus, setContactUpdateStatus} from "../../redux/api";
import {useEffect, useState, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";
import moment from 'moment-timezone';
import Calendar from "../../classes/calendar";
import FollowUp from "../../classes/followUp";

import {setCalendar as clearCalendar, setNeedRefresh} from "../../redux/auth";
import {setError, setShowError} from "../../redux/util";



export default function BookScreen() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const refPrvBtn = useRef(null);
    const refNextBtn = useRef(null);

    const [prvButtonDisabled, setPrvButtonDisabled] = useState(false);
    const [nxtButtonDisabled, setNxtButtonDisabled] = useState(false);

    const followups = useSelector(state => state.auth.followups);
    const [followup, setFollowUp] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const cal = useSelector(state => state.auth.calendar);

    const [apptDate, setApptDate] = useState(null);
    const [calendar, setCalendar] = useState(null);

    const [loading, setLoading] = useState(false);

    const bookStatus = useSelector(state => state.api.bookStatus);
    const apiError = useSelector(state => state.util.error);
    const showError = useSelector(state => state.util.showError);

    useEffect(() => {
        dispatch(clearCalendar(null));
        load();
    }, []);

    useEffect(() => {
        if (bookStatus) {
            load();
            dispatch(setBookStatus(false));
        }
    }, [bookStatus]);

    useEffect(() => {
        if (cal && cal.dateObj) {
            const calendar = new Calendar(cal.raw);
            setCalendar(calendar);
            // calendar.getNextFollowUpIndex();
            console.log('calendar', calendar.dateObj.toString());
            setApptDate(moment(calendar.dateObj));
            setLoading(false);
        }
    }, [cal]);

    useEffect(() => {

        if (!cal && showError) {
            if (currentPage === 0) {
                const found = followups.find(element => element.status === "BOOKED");
                if (!found) {
                    console.log('no bookings, redirecting to home');
                    navigate('/home');
                } else {
                    console.log('has bookings, redirecting to list');
                    navigate('/booking_list');
                }
            } else {
                setCurrentPage(0);
                console.log('going back to first date');
                dispatch(setError({
                    title: "No other dates Available!",
                    message: "Unable to find any available time slots on any other days.",
                    error: null
                }));

                load();
            }
        }
    }, [showError, cal]);

    const load = (page = 0) => {
        setLoading(true);
        const index = getNextFollowUpIndex();

        if (index < 0) {
            console.log('no more followups');
            dispatch(setNeedRefresh(true));
            navigate('/booking_list');
            return;
        }

        const followup = followups[index];
        setFollowUp(followup);

        if (page === 0) {
            dispatch(loadCalendar({followup: followup}));
        } else {
            dispatch(loadCalendar({followup: followup, day:calendar.date, direction: page}));
        }
    }

    const nextDay = () => {
        console.log('next day');
        const currPage = currentPage + 1;
        setCurrentPage(currPage);

        if (currPage >= followup.allowedDateRange) setNxtButtonDisabled(true);
        if (currPage > -1 * followup.allowedDateRange) setPrvButtonDisabled(false);

        load(1);
    }

    const prvDay = () => {
        console.log('prv day');
        const currPage = currentPage - 1;
        setCurrentPage(currPage);

        if (currPage <= -1 * followup.allowedDateRange) setPrvButtonDisabled(true);
        if (currPage < followup.allowedDateRange) setNxtButtonDisabled(false);

        load(-1);
    }

    const getNextFollowUpIndex = () => {
        //return -1; //FOR TESTING

        let prvApptDate = moment();

        for (let i=0; i < followups.length; i++) {

            const followup = followups[i];

            if (followup.isTestOnlyBooking)
            {
                followup.docApptDate = prvApptDate.add(-22, "d");
                followups[i] = followup;
            }

            prvApptDate = followup.nextApptDate;

            if (followup.status === "Received" || followup.status === "Pending") return i;
        }
        return -1;
    }

    const book = (slot) => {
        const fu = {...followup};
        fu.nextApptDate = apptDate.format('YYYY-MM-DD');
        setFollowUp(fu);
        dispatch(createBooking({slot: slot, followup: fu}));
    }

    return (

        <Box>

            <Grid container spacing={3}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Book New Appointment</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                STEP 4: To schedule a new appointment you have to pick a date and a time and press continue.
            </Description>

            <Grid container spacing={0}>
                <Grid item md={12} xs={12}>
                    <QuestionTitle>{followup ? followup.doctor : "--"} - {followup ? followup.appt_in : "--"} {followup ? followup.appt_unit : ""}</QuestionTitle>
                </Grid>

                <Grid item md={2.5} xs={1}>
                    <EditButton ref={refPrvBtn} type="submit" variant="contained" onClick={() => {prvDay()}} disabled={prvButtonDisabled}>Prev</EditButton>
                </Grid>
                <Grid item md={7} xs={10}>
                    <BookingDate>{apptDate ? apptDate.format('dddd') : "--"}</BookingDate>
                    <BookingDate>{apptDate ? apptDate.format('MMMM DD, YYYY') : "--"}</BookingDate>
                </Grid>
                <Grid item md={2.5} xs={1}>
                    <EditButton ref={refNextBtn} type="submit" variant="contained" sx={{float: 'right'}} onClick={() => {nextDay()}} disabled={nxtButtonDisabled}>Next</EditButton>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                    <QuestionTitle sx={{marginTop:'20px !important'}}>Please select a time interval for the appointment or select another day.</QuestionTitle>
                </Grid>

                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot1Available : true} onClick={() => {book("SLOT1")}}>6:30AM - 7:30AM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot2Available : true} onClick={() => {book("SLOT2")}}>7:30AM - 8:30AM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot3Available : true} onClick={() => {book("SLOT3")}}>8:30AM - 9:30AM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot4Available : true} onClick={() => {book("SLOT4")}}>9:30AM - 10:30AM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot5Available : true} onClick={() => {book("SLOT5")}}>10:30M - 11:30AM</TimeSlotButton>
                </Grid>

                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot6Available : true} onClick={() => {book("SLOT6")}}>11:30AM - 12:30PM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot7Available : true} onClick={() => {book("SLOT7")}}>12:30PM - 1:30PM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot8Available : true} onClick={() => {book("SLOT8")}}>1:30PM - 2:30PM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot9Available : true} onClick={() => {book("SLOT9")}}>2:30PM - 3:30PM</TimeSlotButton>
                </Grid>
                <Grid item md={2.4} xs={12}>
                    <TimeSlotButton type="submit" variant="contained" disabled={calendar ? !calendar.isSlot10Available : true} onClick={() => {book("SLOT10")}}>3:30PM - 5:00PM</TimeSlotButton>
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item md={12} xs={12} sx={{marginTop: 5}}><hr/></Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Go Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={true} onClick={() => {navigate('/booking_list');}}>Continue</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>

    );
}

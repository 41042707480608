import {put, call, select} from "redux-saga/effects";
import {setError, setShowError} from "../../util";
import {
    allowedToEditDropsRequest,
    cancelBookingRequest,
    contactUpdateRequest,
    createBookingRequest,
    dropsUpdateRequest,
    fetchDropsRequest,
    loadCalendarRequest, upcomingAppointmentsRequest
} from "../requests/api";
import {
    setBookStatus,
    setCalendarLoadStatus,
    setContactUpdateStatus,
    setDrops,
    setDropsStatus,
    setIsAllowedToEditDrops, setUpcomingAppointments
} from "../../api";
import {setCalendar, updateFollowup} from "../../auth";
import Calendar from "../../../classes/calendar";

export function* contactUpdateHandler(action) {
    const user = yield select(state => state.auth.user);

    try {
        const response = yield call(contactUpdateRequest, user.patient_id, action.payload);
        const {data} = response.data;

        if (data) {
            console.log(data);
            yield put(setContactUpdateStatus(true));
        } else {
            console.warn("API ERROR:", response);
            yield put(setContactUpdateStatus(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* loadCalendarHandler(action) {
    const user = yield select(state => state.auth.user);
    const params = action.payload;

    try {
        const response = yield call(loadCalendarRequest, params.followup, params.day, params.direction);
        const {data} = response.data;

        if (data) {
            const calendar = new Calendar(data);
            const {...calendarObj} = calendar;
            console.log(calendarObj);
            yield put(setCalendar(calendarObj));
            yield put(setCalendarLoadStatus(true));
        } else {
            console.warn("API ERROR:", response);
            yield put(setCalendarLoadStatus(false));
            yield put(setCalendar(null));

            if (response.data && response.data.error_code) {
                if (response.data.error_code === 9001) {
                    yield put(setError({
                        title: "Unable to Find an Available Time!",
                        message: "Unable to Find an Available Time slots. Please contact hospital.",
                        error: response.data.error_code
                    }));
                } else {
                    yield put(setError({
                        title: response.data.error_code,
                        message: "Error occurred. Please contact hospital. <br/>" + response.data.message,
                        error: response.data.error_code
                    }));
                }
            } else {
                yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));
            }

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* createBookingHandler(action) {
    const user = yield select(state => state.auth.user);
    const params = action.payload;

    try {
        const response = yield call(createBookingRequest,params.slot, params.followup, user);
        const {data} = response.data;

        if (data) {
            params.followup.nextApptTime = data.start_time;
            params.followup.nextApptDate = data.date;
            params.followup.status = "BOOKED";
            console.log(params.followup);
            yield put(updateFollowup(params.followup));
            yield put(setBookStatus(true));

            if (!data.email_sent) {
                yield put(setError({
                    title: "Email Sending Failed!",
                    message: "Confirmation email was not sent. However the booking is confirmed. Please contact hospital for more information.",
                    error: null
                }));
                yield put(setShowError(true));
            }

        } else {
            console.warn("API ERROR:", response);
            yield put(setBookStatus(false));

            if (response.data && response.data.error_code) {
                yield put(setError({
                    title: "Booking Failed!",
                    message: response.data.error_code + ": " + response.data.description,
                    error: response.data.error_code
                }));
            } else {
                yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));
            }

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* fetchDropsHandler(action) {
    try {
        const response = yield call(fetchDropsRequest);
        const {data} = response.data;

        if (data) {
            yield put(setDrops(data));
            yield put(setDropsStatus(true));
        } else {
            console.warn("API ERROR:", response);
            yield put(setDropsStatus(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* dropsUpdateHandler(action) {
    const user = yield select(state => state.auth.user);

    try {
        const response = yield call(dropsUpdateRequest, user.patient_id, {eye_drops: action.payload});
        const {data} = response.data;

        if (data) {
            console.log(data);
            yield put(setDropsStatus(true));
        } else {
            console.warn("API ERROR:", response);
            yield put(setDropsStatus(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* cancelBookingHandler(action) {
    const user = yield select(state => state.auth.user);

    try {
        const response = yield call(cancelBookingRequest, action.payload.apptId, action.payload.followupId, action.payload.fromArchive);
        const {data} = response.data;

        if (data) {
            console.log(data);
            yield put(setBookStatus(true));

            yield put(setError({title: data.message, message: data.message, error: null}));
            yield put(setShowError(true));

        } else {
            console.warn("API ERROR:", response);
            yield put(setBookStatus(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));

            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* allowedToEditDropsHandler(action) {
    const user = yield select(state => state.auth.user);

    try {
        const response = yield call(allowedToEditDropsRequest, {patient_id: user.patient_id, dob: user.dob});
        const {data} = response.data;

        if (data) {
            console.log(data);
            yield put(setIsAllowedToEditDrops(data.allowed));
        } else {
            console.warn("API ERROR:", response);
            yield put(setIsAllowedToEditDrops(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));
            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* upcomingAppointmentsHandler(action) {
    const user = yield select(state => state.auth.user);

    try {
        const response = yield call(upcomingAppointmentsRequest, {patient_id: user.patient_id, dob: user.dob});
        const {data} = response.data;

        if (data) {
            console.log(data);
            yield put(setUpcomingAppointments(data.appointments));
        } else {
            console.warn("API ERROR:", response);
            // yield put(setIsAllowedToEditDrops(false));

            yield put(setError({title: "Request Failed!", message: response.statusText, error: response.toString()}));
            yield put(setShowError(true));
        }
    } catch (e) {
        console.log(e);
    }
}

import {Description, MyFormLabel, PrimaryButton, Title} from "../../styles/screens";
import {Box} from "@mui/system";
import {Button, Grid, MenuItem, Select, TextField, FormLabel} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {login} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';


const myHelper = {
    healthcard: {
        required: "Health Card Number is Required",
        pattern: "Invalid Health Card Number"
    },
    reference: {
        required: "Reference Number is Required",
        pattern: "Invalid Reference Number"
    },
    lastname: {
        required: "Last Name is Required",
        pattern: "Invalid Last Name"
    },
    day: {
        required: "Day is Required",
    },
    month: {
        required: "Month is Required",
    },
    year: {
        required: "Year is Required",
    }
};

export default function LoginScreen(effect, deps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const mode = location?.state?.mode;

    const APP_ERROR = useSelector(state => state.util.error);
    const user = useSelector(state => state.auth.user);

    const defaults = {
        lastname: "",
        reference_no: "",
        healthcard: "",
        day: "",
        month: "",
        year: ""
    }

    const [Days, setDays] = useState([]);
    const [Months, setMonths] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
    const [Years, setYears] = useState([]);
    const [captcha, setCaptcha] = useState(null);

    useEffect(() => {
        let tmp = []
        for(let i = 1; i <= 31; i++) {
            tmp.push(i);
        }
        setDays(tmp);

        tmp = []
        for(let i = 1925; i <= 2023; i++) {
            tmp.push(i);
        }
        setYears(tmp);

    }, []);

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const handleOnSubmit = (evt) => {
        console.log('mode:', mode);

        if (mode === 'drops') {
            evt.mode = mode;
            console.log(evt);
            dispatch(login(evt));
        } else {
            console.log(evt);
            dispatch(login(evt));
        }
    };

    function onChange(value) {
        console.log('Captcha value:', value);
        setCaptcha(value);
    }

    useEffect(() => {
        if (user && mode === 'drops') {
            navigate('/manage_drops');
        } else if (user) {
            navigate('/home');
        }
    }, [user]);

    return (
        <Box sx={{}}>
            <Title variant={'h1'}>Welcome to OCC Eyecare</Title>
            <Title variant={'h1'}>{APP_ERROR.message}</Title>
            <Description variant={'body1'}>
                OCC eye care provides our patients to manage their appointments create or update and manage eye drops.
                To access the portal you must have the Health card number and Patient date of birth.
            </Description>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={3}>
                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Last Name</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="lastname"
                            defaultValue={defaults.lastname}
                            rules={{
                                required: true,
                                pattern: /^[A-Z]*$/i
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Last Name"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.lastname[error.type] : "No spaces, numbers or special characters are allowed"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    {mode !== 'drops' && (
                        <>
                            <Grid item md={1.5} xs={0}></Grid>
                            <Grid item md={3} xs={12}>
                                <MyFormLabel>Reference Number</MyFormLabel>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Controller
                                    control={control}
                                    name="reference_no"
                                    defaultValue={defaults.reference_no}
                                    rules={{
                                        required: true,
                                        pattern: /^(?!\s*$).+$/i
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            fullWidth
                                            label="Reference Number"
                                            error={error !== undefined}
                                            helperText={error ? myHelper.reference[error.type] : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={1.5} xs={0}></Grid>
                        </>
                    )}

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Health Card Number</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="healthcard"
                            defaultValue={defaults.healthcard}
                            rules={{
                                required: true,
                                pattern: /^[0-9]{10}$/
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Health Card Number"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.healthcard[error.type] : "No spaces, numbers or special characters are allowed"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Patient Date of Birth</MyFormLabel>
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <Controller
                            control={control}
                            name="month"
                            defaultValue={defaults.month}
                            rules={{
                                required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Month"
                                    // value={currency}
                                    // onChange={handleChange}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error ? myHelper.month[error.type] : ""}
                                >
                                    {Months.map((m, index) => (
                                        <MenuItem key={index} value={index+1}>
                                            {m}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <Controller
                            control={control}
                            name="day"
                            defaultValue={defaults.day}
                            rules={{
                                required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Day"
                                    // value={currency}
                                    // onChange={handleChange}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error ? myHelper.day[error.type] : ""}
                                >
                                    {Days.map((day, index) => (
                                        <MenuItem key={index} value={day}>
                                            {day}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <Controller
                            control={control}
                            name="year"
                            defaultValue={defaults.year}
                            rules={{
                                required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Year"
                                    // value={currency}
                                    // onChange={handleChange}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error ? myHelper.year[error.type] : ""}
                                >
                                    {Years.map((y, index) => (
                                        <MenuItem key={index} value={y}>
                                            {y}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Human Verification</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ReCAPTCHA
                            sitekey="6LckFL8gAAAAAJN_C2oUaI7JvcLMewCM1bDGKMsr"
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={9} xs={12}>
                        <PrimaryButton type="submit" variant="contained" disabled={!captcha}>Login</PrimaryButton>
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>
                </Grid>
            </Box>
        </Box>
    );
}

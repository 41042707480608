import moment from 'moment-timezone';
const Calendar = class {
    calendar = "";
    date;
    dateObj;
    isSlot1Available = false;
    isSlot2Available = false;
    isSlot3Available = false;
    isSlot4Available = false;
    isSlot5Available = false;
    isSlot6Available = false;
    isSlot7Available = false;
    isSlot8Available = false;
    isSlot9Available = false;
    isSlot10Available = false;
    slot1 = [];
    slot2 = [];
    slot3 = [];
    slot4 = [];
    slot5 = [];
    slot6 = [];
    slot7 = [];
    slot8 = [];
    slot9 = [];
    slot10 = [];
    Slot = {
        SLOT1: "SLOT1",
        SLOT2: "SLOT2",
        SLOT3: "SLOT3",
        SLOT4: "SLOT4",
        SLOT5: "SLOT5",
        SLOT6: "SLOT6",
        SLOT7: "SLOT7",
        SLOT8: "SLOT8",
        SLOT9: "SLOT9",
        SLOT10: "SLOT10",
    };

    raw = null;

    constructor(data) {
        this.raw = data;
        this.date = data.date;
        this.calendar = data.calendar;
        this.isSlot1Available = data.isSlot1Available;
        this.isSlot2Available = data.isSlot2Available;
        this.isSlot3Available = data.isSlot3Available;
        this.isSlot4Available = data.isSlot4Available;
        this.isSlot5Available = data.isSlot5Available;
        this.isSlot6Available = data.isSlot6Available;
        this.isSlot7Available = data.isSlot7Available;
        this.isSlot8Available = data.isSlot8Available;
        this.isSlot9Available = data.isSlot9Available;
        this.isSlot10Available = data.isSlot10Available;

        // this.dateObj = Date.parse(this.date);
        // parse date with moment
        this.dateObj = moment(this.date).format('YYYY-MM-DD');

        data.SLOT1.forEach((time) => {
            this.slot1.push(time);
        });
        data.SLOT2.forEach((time) => {
            this.slot2.push(time);
        });
        data.SLOT3.forEach((time) => {
            this.slot3.push(time);
        });
        data.SLOT4.forEach((time) => {
            this.slot4.push(time);
        });
        data.SLOT5.forEach((time) => {
            this.slot5.push(time);
        });
        data.SLOT6.forEach((time) => {
            this.slot6.push(time);
        });
        data.SLOT7.forEach((time) => {
            this.slot7.push(time);
        });
        data.SLOT8.forEach((time) => {
            this.slot8.push(time);
        });
        data.SLOT9.forEach((time) => {
            this.slot9.push(time);
        });
        data.SLOT10.forEach((time) => {
            this.slot10.push(time);
        });
    }
}

export default Calendar;

import {
    AppointmentNumber,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Colors} from "../../styles/theme";
import {useSelector} from "react-redux";


export default function AppointmentListScreen() {
    const navigate = useNavigate();

    const followups = useSelector(state => state.auth.followups);
    const followup = followups[0];

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Book New Appointment</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                STEP 3: Appointments to book
            </Description>

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={1.5} xs={0}>
                </Grid>
                <Grid item md={9} xs={12}>
                    <QuestionTitle>We Request you to book following appointment(s)</QuestionTitle>
                </Grid>
                <Grid item md={1.5} xs={0}>
                </Grid>

                <Grid item md={3} xs={0.1}></Grid>
                <Grid item md={1} xs={2}>
                    <AppointmentNumber>1</AppointmentNumber>
                </Grid>
                <Grid item md={5} xs={8}>
                    <Typography sx={{fontWeight: 'bold'}}>{followup.doctor}</Typography>
                    <Typography>{followup.appt_in} {followup.appt_unit}</Typography>
                </Grid>
                <Grid item md={3} xs={0.1}></Grid>


                <Grid item md={12} xs={12}>
                    <hr/>
                </Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={false} onClick={() => {navigate(-1)}}>Go Back</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {navigate('/book');}}>Continue</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

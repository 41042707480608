import {Description, FormButtonPrimary, HomeButton, PrimaryButton, QuestionTitle, Title} from "../../styles/screens";
import {Grid} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/auth";
import {useEffect} from "react";
import {setError, setShowError} from "../../redux/util";

export default function AppointmentScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const followups = useSelector(state => state.auth.followups);

    useEffect(() => {
        const found = followups.find(element => element.status === "Received" || element.status === "Pending");
        if (!found) {
            console.log('no followup');
            navigate('/home');
            dispatch(setError({
                title: "No Pending Bookings Found! ",
                message: "You have no pending bookings. Please contact hospital for assistance.",
                error: null
            }));
            dispatch(setShowError(true));
        }
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Book New Appointment</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}>
                STEP 1: Confirm your identity
            </Description>

            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={1.5} xs={0}>
                </Grid>
                <Grid item md={9} xs={12}>
                    <QuestionTitle>Please confirm that you are,</QuestionTitle>
                    <Title>{user.first_name} {user.last_name}</Title>
                </Grid>
                <Grid item md={1.5} xs={0}>
                </Grid>

                <Grid item md={12} xs={12}>
                    <hr/>
                </Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {dispatch(logout())}}>No</FormButtonPrimary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={5}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={false} onClick={() => {navigate('/contact_details');}}>Yes</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

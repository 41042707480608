import {Description, MyFormLabel, PrimaryButton, Title} from "../../styles/screens";
import {Box} from "@mui/system";
import {Button, Grid, MenuItem, Select, TextField, FormLabel, TextareaAutosize} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const myHelper = {
    healthcard: {
        required: "Health Card Number is Required",
        pattern: "Invalid Health Card Number"
    },
    name: {
        required: "Name is Required",
        pattern: "Invalid Name"
    },
    email: {
        required: "Email is Required",
        pattern: "Invalid Email"
    },
    phone: {
        required: "Phone No is Required",
        pattern: "Invalid Phone No"
    },
    message: {
        required: "Message is Required",
    }
};

export default function ContactScreen(effect, deps) {
    const navigate = useNavigate();

    const [captcha, setCaptcha] = useState(null);

    useEffect(() => {

    }, []);

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const handleOnSubmit = (evt) => {
        console.log(evt);
        navigate('/');
    };

    function onChange(value) {
        console.log('Captcha value:', value);
        setCaptcha(value);
    }

    return (
        <Box sx={{}}>
            <Title variant={'h1'}>Contact Us</Title>
            <Description variant={'body1'}>
                Send us your inquiry and we will get back to you soon.
            </Description>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={3}>
                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Name</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="name"
                            defaultValue=""
                            rules={{
                                required: true,
                                // pattern: /^[A-Z]*$/i
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Your Name"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.name[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Phone Number</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="phone"
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: /^[0-9]*$/i
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Phone Number"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.phone[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Email</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: /^[a-z]*\d*$/i
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Email Address"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.email[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Health Card Number</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="healthcard"
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: /^[0-9]{8}$/
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    fullWidth
                                    label="Health Card Number"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.healthcard[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Message</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            control={control}
                            name="message"
                            defaultValue=""
                            rules={{
                                required: true,
                                // pattern: /^[0-9]{8}$/
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    multiline
                                    rows={6}
                                    maxRows={100}
                                    fullWidth
                                    label="Message"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.message[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>


                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={3} xs={12}>
                        <MyFormLabel>Human Verification</MyFormLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ReCAPTCHA
                            sitekey="6LckFL8gAAAAAJN_C2oUaI7JvcLMewCM1bDGKMsr"
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>

                    <Grid item md={1.5} xs={0}></Grid>
                    <Grid item md={9} xs={12}>
                        <PrimaryButton type="submit" variant="contained" disabled={!captcha}>Submit</PrimaryButton>
                    </Grid>
                    <Grid item md={1.5} xs={0}></Grid>
                </Grid>
            </Box>
        </Box>
    );
}

import axios from "../../../utils/Api";

export function contactUpdateRequest(chart_no, data) {
    console.log(data);
    return axios.post ("/contact_details/patient/" + chart_no, data);
}

export function loadCalendarRequest(followup, day = null, direction = 1) {

    let url = "booking/calendar/" + followup.appt_interval + "/" + followup.pvdrno;

    if (day != null)
    {
        url = "booking/anotherDay/" + day + "/" + followup.pvdrno + "/" + direction;
    }

    if (followup.isTestOnlyBooking && followup.docApptDate != null && day == null) {
        url = "booking/anotherDay/" + followup.docApptDate + "/" + 0 + "/" + direction;
    }

    if (followup.isTestOnlyBooking && followup.docApptDate != null && day != null)
    {
        url = "booking/anotherDay/" + day + "/" + 0 + "/" + direction;
    }

    if (followup.hasTests)
    {
        url += "/" + followup.tests.join("/");
    }

    return axios.get ("/" + url);
}

export function createBookingRequest(slot, followup, user) {
    let url = "/booking/calendar/book/" + followup.id;

    if (followup.hasTests) {
        url += "/" + followup.tests.join("/");
    }

    console.log("booking: " + url);

    const followupObj = {
        id: followup.id,
        doctor_name: followup.doctor,
        pvdrno: followup.pvdrno,
        chart_no: followup.chart_no,
        dob: user.dob,
        appt_in: followup.appt_in,
        appt_unit: followup.appt_unit,
        first_name: user.first_name,
        last_name: user.last_name,
        home_phone: user.home_phone,
        mobile_phone: user.mobile_phone,
        email: user.email,
        address_street: user.address_street ? user.address_street : null,
        address_city: user.address_city ? user.address_city : null,
        address_postalcode: user.address_postalcode ? user.address_postalcode : null,
        gender: user.gender,
        test_only_booking: followup.isTestOnlyBooking,
        bookno: followup.bookno,
        ref_no: followup.ref_no,
        from_archive: followup.fromArchive,
    };

    //console.log(followupObj);
    //console.log(followup);

    return axios.post(url, {
        date: followup.nextApptDate,
        time_slot: slot,
        pvdrno: followup.pvdrno,
        followup: JSON.stringify(followupObj)
    });
}

export function fetchDropsRequest() {
    return axios.get ("/eye_drops");
}

export function dropsUpdateRequest(patient_id, data) {
    return axios.post ("/patient/" + patient_id + "/profile/partial", data);
}

export function cancelBookingRequest(apptId, followupId, fromArchive = false) {
    return axios.post ("/cancel/followup/" + followupId, {apptId: apptId, fromArchive: fromArchive, followupId: followupId});
}

export function allowedToEditDropsRequest(data) {
    return axios.post ("/drops/edit/isAllowed", data);
}

export function upcomingAppointmentsRequest(data) {

    return axios.post ("/appointments/upcoming", data);
}

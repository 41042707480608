import "./index.css";
import {
    Container,
    Typography,
    Box,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Button
} from "@mui/material";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Appbar from "./components/appbar";
import theme from "./styles/theme";
import {UIProvider} from "./context/ui";
import Footer from "./components/footer";
import AppDrawer from "./components/drawer";
import {useEffect} from "react";
import {ThemeProvider} from "@mui/system";
import ContactScreen from "./screens/contact";
import LoginScreen from "./screens/login";
import HomeScreen from "./screens/home";
import LandingScreen from "./screens/landing";
import AppointmentScreen from "./screens/appointments";
import ErrorScreen from "./screens/error";
import ContactDetailsScreen from "./screens/appointments/contact_details";
import AppointmentListScreen from "./screens/appointments/appointment_list";
import BookingListScreen from "./screens/appointments/booking_list";
import BookScreen from "./screens/appointments/book";
import DropsScreen from "./screens/drops";
import AddDropsScreen from "./screens/drops/add_drops";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "./redux/auth";
import PrivateRoutes from "./utils/PrivateRoutes";
import {setShowError} from "./redux/util";
import OverlayLoader from "overlay-loading-react";
import RescheduleScreen from "./screens/reschedule";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {

    }, []);

    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const showError = useSelector(state => state.util.showError);
    const appError = useSelector(state => state.util.error);
    const loading = useSelector(state => state.util.loading);
    console.log(user);

    useEffect(() => {
        document.title = "OCC EyeCare: Online Booking Portal";

        if (token && token.length) {
            const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
            const expiration = new Date(jwtPayload.exp * 1000);
            const now = new Date();

            if (expiration.getTime() <= now.getTime()) {
                console.log("Token expired!!");
                dispatch(logout());
            }
        }
    }, []);

    const closeError = () => {
        dispatch(setShowError(false));
    }

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <Container
                    disableGutters
                    maxWidth="xl"
                    sx={{
                        background: "#fff",
                    }}
                >
                    <Stack>
                        <UIProvider>
                            <Appbar/>
                            <Box display="flex" justifyContent="center" sx={{
                                p: 4,
                                minHeight: 'calc(100vh - 225px)',
                                maxWidth: 1024,
                                m: 'auto',
                                width: {md: 'calc(100vw - 100px)', sx: '100%'}
                            }}>
                                <Routes>
                                    <Route path="/" element={<LandingScreen/>} exact/>
                                    <Route path="/login" element={<LoginScreen/>}/>
                                    <Route path="/contact_us" element={<ContactScreen/>}/>

                                    <Route element={<PrivateRoutes />}>
                                        <Route path="/home" element={<HomeScreen/>}/>
                                        <Route path="/appointments" element={<AppointmentScreen/>}/>
                                        <Route path="/contact_details" element={<ContactDetailsScreen/>}/>
                                        <Route path="/appointment_list" element={<AppointmentListScreen/>}/>
                                        <Route path="/book" element={<BookScreen/>}/>
                                        <Route path="/booking_list" element={<BookingListScreen/>}/>
                                        <Route path="/manage_drops" element={<DropsScreen/>}/>
                                        <Route path="/add_drops" element={<AddDropsScreen/>}/>
                                        <Route path="/reschedule" element={<RescheduleScreen/>}/>
                                    </Route>

                                    <Route path="*" element={<ErrorScreen/>}/>
                                </Routes>
                            </Box>
                            <Footer/>
                            <AppDrawer/>
                        </UIProvider>
                    </Stack>
                </Container>
                <Dialog
                    open={showError}
                    onClose={closeError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {appError.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {appError.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeError} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <OverlayLoader loadingText='Loading...' active={loading} overlayContainerStyle={{"zIndex": "9999", "backgroundColor": "rgba(0, 0, 0, 0.8)"}} contentContainerStyle={{"color": "#fff"}} />
            </ThemeProvider>
        </Router>
    );
}

export default App;

import FollowUp from "./followUp";
const Patient = class {

    followUps = [];
    eye_drops = [];
    hasTests = false;

    noOfTests = 0;
    testCost = 0.0;

    hasDurationError = false;
    haveDrops = false;
    isNewConsent = false;
    cue = [];
    chartNo = [];
    drops = "";
    patient_id = "";
    first_name = "";
    last_name = "";
    dob = "";
    home_phone = "";
    mobile_phone = "";
    email = "";
    gender = "";

    drops_updated_at = "";

    health_card = "";

    constructor(data) {
        this.patient_id = data.profile.patient_id;
        this.first_name = data.profile.first_name;
        this.last_name = data.profile.last_name;
        this.dob = data.profile.dob;
        this.home_phone = data.profile.home_phone;
        this.mobile_phone = data.profile.mobile_phone;
        this.email = data.profile.email;
        this.gender = data.profile.sex;
        this.eye_drops = data.profile.eye_drops ? data.profile.eye_drops : [];
        this.drops_updated_at = data.profile.drops_updated_at;
        this.health_card = data.profile.health_card;

        if (data.followup && data.followup.id) {
            const tmp = new FollowUp();
            // tmp.doctor = data.followup.doctor_name;
            tmp.doctor = data.followup.doctor_name.includes("Dr.") ? data.followup.doctor_name : `Dr. ${data.followup.doctor_name}`;

            tmp.appt_in = data.followup.appt_in;
            if (isNaN(tmp.appt_in)) tmp.appt_in = 0;

            tmp.appt_unit = data.followup.appt_unit;
            tmp.id = data.followup.id;
            tmp.pvdrno = data.followup.pvdrno;
            tmp.ref_no = data.followup.ref_no;
            tmp.status = data.followup.status;
            tmp.tests = data.followup.tests;
            tmp.chart_no = data.followup.chart_no;
            tmp.bookno = data.followup.bookno;

            tmp.drops = data.followup.drops;

            tmp.isSameDay = data.followup.is_same_day;

            tmp.haveDrops = data.followup.have_drops;

            if (tmp.status === "New-Consent") {
                tmp.isNewConsent = true;
                this.isNewConsent = true;
            }

            if (tmp.isNewConsent) {
                this.cue = data.followup.consents_list;
                this.chartNo.push(tmp.chart_no);
            }

            if (tmp.haveDrops) {
                this.haveDrops = true;
            }

            if (this.drops !== "") {
                this.drops += "," + tmp.drops;
            } else {
                this.drops = tmp.drops;
            }

            if (tmp.appt_in < 1 || tmp.appt_unit === "." || tmp.appt_unit === "") {
                this.hasDurationError = true;
            }

            tmp.costs = data.followup.costs;
            let keys = Object.keys(tmp.costs);

            keys.forEach((key) => {
                if (!isNaN(tmp.costs[key])) {
                    this.testCost += tmp.costs[key];
                }
            });



            tmp.calcDays();


            tmp.tests = tmp.tests.filter(function (item) {
                return item !== "OCT" && item !== "OCT-G" && item !== "PE" && item !== "SM" && item !== "TP";
            });


            let nonSameDayTests = [];

            if (!tmp.isSameDay) {
                //VF-24, VF-10, HRT, PACH
                if (tmp.tests.includes("VF-24")) {
                    tmp.tests = tmp.tests.filter(function (item) {
                        return item !== "VF-24";
                    });
                    nonSameDayTests.push("VF-24");
                }
                if (tmp.tests.includes("VF-10")) {
                    tmp.tests = tmp.tests.filter(function (item) {
                        return item !== "VF-10";
                    });
                    nonSameDayTests.push("VF-10");
                }
                if (tmp.tests.includes("HRT")) {
                    tmp.tests = tmp.tests.filter(function (item) {
                        return item !== "HRT";
                    });
                    nonSameDayTests.push("HRT");
                }
                if (tmp.tests.includes("PACH")) {
                    tmp.tests = tmp.tests.filter(function (item) {
                        return item !== "PACH";
                    });
                    nonSameDayTests.push("PACH");
                }
            }

            if (tmp.tests.length > 0) tmp.hasTests = true;

            if (tmp.tests.length > this.noOfTests) {
                this.noOfTests = tmp.tests.length;
            }

            if (data.followup.from_archive) {
                tmp.fromArchive = true;
                tmp.nextApptDate = data.followup.next_appt;
            }

            this.followUps.push(tmp);

            if (nonSameDayTests.length > 0) {
                const newRec = this.generateFollowupForTests(tmp, nonSameDayTests);
                this.followUps.push(newRec);

                if (newRec.tests.length > this.noOfTests) {
                    this.noOfTests = newRec.tests.length;
                }
                if (newRec.tests.length > 0) this.hasTests = true;
            }


            if (tmp.tests.length > 0) this.hasTests = true;
        }

    }

    generateFollowupForTests(original, tests) {
        const newRec = new FollowUp();
        newRec.isTestOnlyBooking = true;
        newRec.hasTests = true;
        newRec.tests = tests;

        newRec.doctor = "Tests: "  + tests.join(",");
        newRec.appt_in = original.appt_in;
        newRec.appt_unit = original.appt_unit;
        newRec.id = original.id;
        newRec.pvdrno = original.pvdrno;
        newRec.ref_no = original.ref_no;
        newRec.status = original.status;

        newRec.chart_no = original.chart_no;

        newRec.isSameDay = true;

        newRec.calcDays();

        return newRec;
    }

    getFollowUpsArray() {
        let ret = [];

        this.followUps.forEach(followup => {
            const {...followupsObj} = followup;
            ret.push(followupsObj);
        });

        return ret;
    }
}

export default Patient;

import {DashboardButton, Description, HomeButton, Title} from "../../styles/screens";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {login, logout, setNeedRefresh} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment-timezone";
import {useEffect} from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {LogoutOutlined} from "@mui/icons-material";

export default function HomeScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const followups = useSelector(state => state.auth.followups);
    const needRefresh = useSelector(state => state.auth.needRefresh);

    useEffect(() => {
        if (needRefresh) {
            const data = {
                day: user.dob.slice(8, 10),
                healthcard: user.health_card,
                lastname: user.last_name,
                month: user.dob.slice(5, 7),
                year: user.dob.slice(0, 4)
            }

            if (followups && followups.length && followups[0].ref_no) {
                data.reference_no = followups[0].ref_no;
            } else {
                data.mode = "drops";
            }

            dispatch(login(data));
            dispatch(setNeedRefresh(false));
        }

    }, [needRefresh]);

    const getLastUpdatedDate = () => {
        if (user && user.drops_updated_at) {
            try {
                const date = moment(user.drops_updated_at, "YYYY-MM-DD");
                return date.format('MMMM DD, YYYY');
            } catch (e) {

            }
        }

        return "N/A";
    }

    return (
        <Box sx={{width: '100%'}}>
            <Title variant={'h1'}>Patients Dashboard</Title>
            {followups.length ? (
                <>
                <Description variant={'body1'}>
                    <br/>
                    Below 3 links provide you to schedule an appointment or reschedule your existing appointment and manage your eye drops.
                </Description>

                <Grid container spacing={3} sx={{width: '100%'}}>
                    <Grid item md={4} xs={12}>
                        <DashboardButton type="submit" variant="contained" onClick={() => {navigate('/appointments');}}>Book an Appointment</DashboardButton>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <DashboardButton type="submit" variant="contained" onClick={() => {navigate('/reschedule');}}>Reschedule an Appointment</DashboardButton>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <DashboardButton sx={{backgroundColor: '#0F52BA'}} type="submit" variant="contained" onClick={() => {navigate('/manage_drops');}}>Manage Drops</DashboardButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{width: '100%'}}>
                    <Grid item md={4} xs={0}>

                    </Grid>
                    <Grid item md={4} xs={0}>

                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Description variant={'body1'}>
                            Last Updated: <b>{getLastUpdatedDate()}</b>
                        </Description>
                    </Grid>
                </Grid>
                </>
            ) : (
                <>
                    <Description variant={'body1'}>
                        <br/>
                        You can manage your eye drops 48hrs prior to the appointment.
                    </Description>

                    <Grid container spacing={3} sx={{width: '100%'}}>
                        <Grid item md={4} xs={0}>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <DashboardButton sx={{backgroundColor: '#0F52BA'}} type="submit" variant="contained" onClick={() => {navigate('/manage_drops');}}>Manage Drops</DashboardButton>
                        </Grid>
                        <Grid item md={4} xs={0}>

                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{width: '100%'}}>
                        <Grid item md={4} xs={0}>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Description variant={'body1'}>
                                Last Updated: <b>{getLastUpdatedDate()}</b>
                            </Description>
                        </Grid>
                        <Grid item md={4} xs={0}>

                        </Grid>
                    </Grid>
                </>
            )}

            <Grid container spacing={3} sx={{width: '100%'}}>


                <Grid item md={12} xs={12} sx={{textAlign: 'center'}}>
                    <HomeButton type="submit" variant="contained" onClick={() => {dispatch(logout())}}>
                         Logout &nbsp; <LogoutOutlined sx={{verticalAlign: 'middle', marginTop: -0.4}} />
                    </HomeButton>
                </Grid>

            </Grid>

        </Box>
    );
}

import {
    AppointmentNumber,
    Description, EditButton,
    FormButtonPrimary, FormButtonSecondary,
    HomeButton,
    MyFormLabel,
    PrimaryButton,
    QuestionTitle, RemoveButton,
    Title
} from "../../styles/screens";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Box, width} from "@mui/system";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {useNavigate} from "react-router-dom";
import {Colors} from "../../styles/theme";
import moment from "moment-timezone";
import {useDispatch, useSelector} from "react-redux";
import {removeDrop, setNeedRefresh} from "../../redux/auth";
import {getAllowedToEditDrops, setDropsStatus, updateDrops} from "../../redux/api";
import {useEffect} from "react";
import {setError, setShowError} from "../../redux/util";


export default function DropsScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const dropsStatus = useSelector(state => state.api.dropsStatus);
    const isAllowedToEditDrops = useSelector(state => state.api.isAllowedToEditDrops);

    useEffect(() => {
        console.log("getAllowedToEditDrops");
        dispatch(getAllowedToEditDrops());
    }, []);

    const getLastUpdatedDate = () => {
        if (user && user.drops_updated_at) {
            try {
                const date = moment(user.drops_updated_at, "YYYY-MM-DD");
                return date.format('MMMM DD, YYYY');
            } catch (e) {

            }
        }

        return "N/A";
    }

    const remove = index => {
        dispatch(removeDrop(index));
    }

    const done = () => {
        dispatch(updateDrops(JSON.stringify(user.eye_drops)));
    }

    useEffect(() => {
        if (dropsStatus) {
            navigate('/home');
            dispatch(setDropsStatus(false));
            dispatch(setNeedRefresh(true));
        }
    }, [dropsStatus]);

    useEffect(() => {
        if (!isAllowedToEditDrops) {
            dispatch(setError({title: "Can't Edit Drops!", message: "You can only edit drops 48 hours before the next appointment.", error: null}));
            dispatch(setShowError(true));
        } else {
            dispatch(setShowError(false));
        }
    }, [isAllowedToEditDrops]);

    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={3} sx={{width: '100%'}}>
                <Grid item md={2} xs={12}>
                    <HomeButton type="submit" variant="contained" onClick={() => {navigate('/home');}}>
                        <HomeOutlinedIcon sx={{verticalAlign: 'middle', marginTop: -0.4}} /> Back to Home
                    </HomeButton>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Title variant={'h1'}>Manage Drops</Title>
                </Grid>
                <Grid item md={2} xs={0}></Grid>
            </Grid>
            <Description variant={'body1'}></Description>

            <QuestionTitle>Eye Drops for {user.first_name + ' ' + user.last_name}</QuestionTitle>
            <QuestionTitle sx={{marginTop: '-10px!important', fontSize: '16px!important', color: '#C41E3A!important'}}>Last Updated: <b>{getLastUpdatedDate()}</b></QuestionTitle>

            <Typography variant='body1'><b>Selected Drop(s),</b></Typography>
            <Grid container spacing={3} sx={{marginTop: 0.5}}>

                {user.eye_drops.map((drop, i) => {
                    return (
                        <>
                        <Grid item md={8} xs={8} key={i}>
                            <Typography>{drop}</Typography>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <RemoveButton disabled={!isAllowedToEditDrops} onClick={() => {remove(i)}}>Remove</RemoveButton>
                        </Grid>
                        </>
                    )
                })}

                <Grid item md={12} xs={12}>
                    <hr/>
                </Grid>

                <Grid item md={1} xs={0}>
                </Grid>
                <Grid item md={4} xs={6}>
                    <FormButtonSecondary type="submit" variant="contained" disabled={!isAllowedToEditDrops} onClick={() => {navigate('/add_drops')}}>ADD DROPS</FormButtonSecondary>
                </Grid>
                <Grid item md={2} xs={0}>
                </Grid>
                <Grid item md={4} xs={4}>
                    <FormButtonPrimary type="submit" variant="contained" disabled={!isAllowedToEditDrops} onClick={() => {done()}}>Done</FormButtonPrimary>
                </Grid>
                <Grid item md={1} xs={0}>
                </Grid>
            </Grid>

        </Box>
    );
}

import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { useUIContext } from "../../context/ui";
import CloseIcon from "@mui/icons-material/Close";
import {AppbarHeader, DrawerCloseButton, MyListItem, MyListItemText} from "../../styles/appbar";
import { lighten } from "polished";
import { Colors } from "../../styles/theme";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;


export default function AppDrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();

  return (
    <>
      {drawerOpen && (
        <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon
            sx={{
              fontSize: "2.5rem",
              color: lighten(0.09, Colors.white),
            }}
          />
        </DrawerCloseButton>
      )}
      <Drawer open={drawerOpen}>

        <List>
          <a href="/home">
            <img src="/occ_logo.png" alt="Logo" style={{padding:'10px'}}/>
          </a>
          <MyListItem button component="a" href="http://occeyecare.ca/">
            <MyListItemText primary="OCC HOME"/>
          </MyListItem>
          <MiddleDivider />
          <MyListItem button component="a" href="http://occeyecare.ca/COVID19/">
            <MyListItemText primary="COVID19 UPDATE"/>
          </MyListItem>
          <MiddleDivider />
          <MyListItem button component="a" href="http://occeyecare.ca/about_us/">
            <MyListItemText primary="ABOUT"/>
          </MyListItem>
          <MiddleDivider />
          <MyListItem button component="a" href="http://occeyecare.ca/new_patients/">
            <MyListItemText primary="PATIENTS"/>
          </MyListItem>
          <MiddleDivider />
          <MyListItem button component="a" href="http://occeyecare.ca/Treatments_and_Tests/">
            <MyListItemText primary="TREATMENTS AND TESTS"/>
          </MyListItem>
          <MiddleDivider />
          <MyListItem button component="a" href="http://occeyecare.ca/contact_us/">
            <MyListItemText primary="CONTACT US"/>
          </MyListItem>
          <MiddleDivider />
        </List>
      </Drawer>
    </>
  );
}

import axios from "../../../utils/Api";

export function loginRequest(data) {
    console.log(data);
    // return axios.post ("/api/auth", {
    //     card_number: "3784026282"
    // });

    if (data.mode === "drops") {
        return axios.post ("/login-drops", data);
    } else {
        return axios.post("/login", data);
    }

    // return axios.get("/ebooking/patient/barcode/80144");
}

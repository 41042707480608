import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contactUpdateStatus: false,
    calendarLoadStatus: false,
    bookStatus: false,
    dropsStatus: false,
    drops: [],
    isAllowedToEditDrops: false,
    upcomingAppointments: [],
}

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        contactUpdate: () => {},
        setContactUpdateStatus: (state, action) => {
            state.contactUpdateStatus = action.payload;
        },
        loadCalendar: () => {},
        setCalendarLoadStatus: (state, action) => {
            state.calendarLoadStatus = action.payload;
        },
        createBooking: () => {},
        setBookStatus: (state, action) => {
            state.bookStatus = action.payload;
        },
        fetchDrops: () => {},
        updateDrops: () => {},
        setDrops: (state, action) => {
            state.drops = action.payload;
        },
        setDropsStatus: (state, action) => {
            state.dropsStatus = action.payload;
        },
        cancelBooking: () => {},
        getAllowedToEditDrops: () => {},
        setIsAllowedToEditDrops: (state, action) => {
            state.isAllowedToEditDrops = action.payload;
        },
        fetchUpcomingAppointments: () => {},
        setUpcomingAppointments: (state, action) => {
            state.upcomingAppointments = action.payload;
        },
    },
});

export const { contactUpdate, setContactUpdateStatus, loadCalendar, setCalendarLoadStatus, createBooking, setBookStatus, fetchDrops, setDropsStatus, setDrops, updateDrops, cancelBooking, getAllowedToEditDrops, setIsAllowedToEditDrops, fetchUpcomingAppointments, setUpcomingAppointments } = apiSlice.actions;

export default apiSlice.reducer;

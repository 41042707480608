import {takeLatest} from "redux-saga/effects";
import {login} from "../auth";
import {loginHandler} from "./handlers/auth";
import {
    cancelBooking,
    contactUpdate,
    createBooking,
    fetchDrops, fetchUpcomingAppointments,
    getAllowedToEditDrops,
    loadCalendar,
    updateDrops
} from "../api";
import {
    allowedToEditDropsHandler,
    cancelBookingHandler,
    contactUpdateHandler,
    createBookingHandler,
    dropsUpdateHandler,
    fetchDropsHandler,
    loadCalendarHandler, upcomingAppointmentsHandler
} from "./handlers/api";

export function* rootSaga() {
    yield takeLatest(login.type, loginHandler);
    yield takeLatest(contactUpdate.type, contactUpdateHandler);
    yield takeLatest(loadCalendar.type, loadCalendarHandler);
    yield takeLatest(createBooking.type, createBookingHandler);
    yield takeLatest(fetchDrops.type, fetchDropsHandler);
    yield takeLatest(updateDrops.type, dropsUpdateHandler);
    yield takeLatest(cancelBooking.type, cancelBookingHandler);
    yield takeLatest(getAllowedToEditDrops.type, allowedToEditDropsHandler);
    yield takeLatest(fetchUpcomingAppointments.type, upcomingAppointmentsHandler);
}
